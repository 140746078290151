/* Show on small devices */
@media only screen and (max-width: 768px) {
  .mobileQuote {
    display: block;
  }
}

/* Hide on larger devices */
@media only screen and (min-width: 768px) {
  .mobileQuote {
    display: none;
  }
}
