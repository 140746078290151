@media screen and (max-width: 768px) {
  .detail {
    display: none !important;
  }
}


@media screen and (min-width: 768px) {
  .mini {
    display: none !important;
  }
}
