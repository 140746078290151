.hero {
  background-image: url("../../../images/bg.webp");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}



.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
}

.heading h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0;
}

.heading h2 {
  font-size: 1.5rem;
  color: #777;
  margin: 0;
  text-transform: uppercase;
}

@media screen and (max-width: 768px) {
  .margin {
    height: 30vh;
  }
  .hero {
    background-size: fit;
    background-position: right;
    padding-top: 50%;
    height: 80vh;
    width: 100%;
  }
  .hero .row {
    width: 100%;
  }
}
